<template>
  <div class="custom">
    <!-- header de titulo y acciones -->
    <headerLayaut> </headerLayaut>
    <!-- contenido de la pagina -->

    <div class="body-template">
      <v-card>
        <v-card-title
          >Agregar campos <v-spacer></v-spacer>
          <v-btn @click="openSort" color="primary"> Ordenar </v-btn>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="8">
              <div
                class="item-inputs mt-2 px-2"
                v-for="(inputs, i) in customForm.customFields"
                :key="i"
              >
                <v-switch
                  dense
                  label="Requerido"
                  v-model="inputs.required"
                ></v-switch>
                <v-select
                  style="border-radius: 10px"
                  class="mx-2"
                  filled
                  hide-details
                  rounded
                  dense
                  :items="types"
                  v-model="inputs.type"
                  label="tipo"
                ></v-select>
                <v-menu
                  offset-y
                  v-if="inputs.type == 'select'"
                  :nudge-width="200"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      x-small
                      class="mr-2"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> fa fa-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row
                          v-for="(item, index) in inputs.items"
                          :key="index"
                        >
                          <v-col cols="5">
                            <v-text-field
                              style="border-radius: 10px"
                              v-model="item.text"
                              required
                              label="Text"
                              filled
                              dense
                              hide-details
                              rounded
                            ></v-text-field>
                          </v-col>
                          <v-col cols="5">
                            <v-text-field
                              style="border-radius: 10px"
                              v-model="item.value"
                              required
                              label="Value"
                              filled
                              dense
                              hide-details
                              rounded
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-btn
                              icon
                              outlined
                              color="primary"
                              class="mt-2"
                              @click="
                                inputs.items = inputs.items.filter(
                                  (x) => x != item
                                )
                              "
                              small
                            >
                              <v-icon fab small> fa fa-close </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-col cols="12">
                          <v-btn
                            block
                            text
                            color="primary"
                            @click="inputs.items.push({ text: '', value: '' })"
                          >
                            Agregar +
                          </v-btn>
                        </v-col>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-menu>
                <v-text-field
                  style="border-radius: 10px"
                  v-model="inputs.label"
                  required
                  label="Label"
                  filled
                  hide-details
                  rounded
                ></v-text-field>
                <!-- <v-select
                  style="border-radius: 10px"
                  class="mx-2"
                  filled
                  hide-details
                  rounded
                  dense
                  :items="types"
                  v-model="inputs.type"
                  label="tipo"
                ></v-select> -->

                <v-select
                  style="border-radius: 10px"
                  filled
                  hide-details
                  rounded
                  class="mx-2"
                  label="tamaño"
                  dense
                  :items="sizes"
                  v-model="inputs.size"
                ></v-select>
                <v-btn
                  :dark="$vuetify.theme.dark"
                  fab
                  elevation="0"
                  small
                  @click="add"
                  color="primary"
                >
                  <v-icon dark small> fas fa-plus </v-icon>
                </v-btn>
                <v-btn
                  :dark="$vuetify.theme.dark"
                  fab
                  elevation="0"
                  outlined
                  color="error"
                  class="ml-2"
                  style="border-radius: 10px"
                  small
                  @click="
                    customForm.customFields = customForm.customFields?.filter(
                      (x) => x != inputs
                    )
                  "
                >
                  <v-icon fab small> fa fa-close </v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <v-form>
                <v-row v-if="customForm">
                  <v-col
                    v-for="(inputs, i) in customForm.customFields"
                    :key="i"
                    :cols="inputs.size"
                  >
                    <label for="" class="text-caption">{{
                      inputs.label
                    }}</label>
                    <v-select
                      v-if="inputs.type == 'select'"
                      filled
                      hide-details
                      style="border-radius: 10px"
                      rounded
                      :items="inputs.items"
                      v-model="inputs.value"
                    ></v-select>
                    <v-text-field
                      v-if="inputs.type == 'text'"
                      style="border-radius: 10px"
                      v-model="inputs.value"
                      required
                      :type="inputs.type"
                      filled
                      hide-details
                      rounded
                    ></v-text-field>
                    <v-text-field
                      v-if="inputs.type == 'tel'"
                      style="border-radius: 10px"
                      v-model="inputs.value"
                      required
                      :type="inputs.type"
                      filled
                      hide-details
                      rounded
                    ></v-text-field>
                    <v-switch
                      v-if="inputs.type == 'switch'"
                      dense
                      :label="inputs.label"
                      v-model="inputs.required"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-switch label="Activo" v-model="customForm.active"></v-switch>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="save"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <v-dialog
      v-model="sortCustomFields"
      max-width="400px"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            small
            absolute
            @click="sortCustomFields = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>

          Ordenar
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <draggable
            v-model="allCustomField"
            group="people"
            @start="drag = true"
            @end="drag = false"
          >
            <v-list-item
              v-for="(element, index) in allCustomField"
              :key="index"
              class="grab"
              :class="{ linea: index < allCustomField.length - 1 }"
            >
              <v-list-item-title class="list-wrap-img">
                {{ element.label }}
              </v-list-item-title>
              <v-icon color="primary"> fa-solid fa-sort </v-icon>
            </v-list-item>
          </draggable>
        </v-card-text>
        <v-divider> </v-divider>
        <v-card-actions class="flex-end">
          <v-btn
            color="primary"
            style="border-radius: 10px"
            width="200px"
            @click="saveSort"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import headerLayaut from "@/components/header-layaut.vue";

import draggable from "vuedraggable";
import { db, fb } from "@/firebase";
export default {
  name: "sections",

  components: {
    headerLayaut,

    draggable,
  },
  data() {
    return {
      requiredRule: [(v) => !!v || "Requerido"],
      valid: true,
      customForm: {
        customFields: [],
      },
      allCustomField: [],

      dialogEdit: false,
      dataEdit: null,
      sortCustomFields: false,
      btnLoading: null,
      deleteConfirm: false,
      deletedkey: null,
      dialog: false,
      event: [],
      types: [
        {
          text: "Select",
          value: "select",
        },
        {
          text: "Texto",
          value: "text",
        },
        {
          text: "Numerico",
          value: "tel",
        },
        {
          text: "switch",
          value: "switch",
        },
      ],
      sizes: [
        {
          text: "Medium",
          value: "6",
        },
        {
          text: "Large",
          value: "12",
        },
      ],
    };
  },
  computed: {
    ...mapState(["selectedEvent", "user", "business"]),
  },
  methods: {
    ...mapActions(["visibleSearch", "Alert_", "addBusiness", "_searchTerm"]),
    openSort() {
      this.allCustomField = Object.assign([], this.customForm.customFields);
      this.sortCustomFields = true;
    },
    add() {
      this.customForm.customFields.push({
        required: false,
        label: "",
        postion: 0,
        size: "6",
        type: "text",
        value: "",
        items: [],
      });
    },
    async saveSort() {
      for (let i = 0; i < this.allCustomField.length; i++) {
        const element = this.allCustomField[i];
        element.position = i;
      }
      this.customForm.customFields = this.allCustomField;
      this.sortCustomFields = false;
    },
    save() {
      db.collection("events")
        .doc(this.selectedEvent[`.key`])
        .update({ customForm: this.customForm })
        .then((respons) => {
          this.Alert_({
            text: "Información guardada",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "info",
          });
        });
    },
  },
  async mounted() {
    this._searchTerm("Formulario");
    this.visibleSearch(true);
    await this.$binding(
      "event",
      db.collection("events").doc(this.selectedEvent[`.key`])
    );

    this.customForm = this.event.customForm
      ? Object.assign({}, this.event.customForm)
      : this.customForm;
    if (this.customForm.customFields.length > 0) {
    } else {
      this.add();
    }
  },
};
</script>
<style scoped>
.linea {
  border-bottom: 1px solid rgba(128, 128, 128, 0.088);
}
.item-inputs {
  background-color: #393244;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>